"use client";
import "@/styles/globals.css";
import type { AppProps } from "next/app";
import { SWRConfig } from "swr";
import { CookiesProvider } from "react-cookie";
import fetchJson from "../lib/fetchJson";
import Head from "next/head";
import { Poppins} from "next/font/google";
import { NextPageContext } from "next";
import { Theme } from '@radix-ui/themes';

import '@radix-ui/themes/styles.css';
import "../styles/searchbar.css"
import "../styles/hovertext.css"
import "../styles/wizard.css"
import '../styles/daterangepicker.css';
import "@ag-grid-community/styles/ag-grid.css"
import "@ag-grid-community/styles/ag-theme-quartz.css"
import dynamic from 'next/dynamic'
import { useEffect, useLayoutEffect, useState } from "react";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import { SuperTokensProvider } from "@/components/SupertokensProvider";

TimeAgo.addDefaultLocale(en)

const poppins = Poppins({
  subsets: ["latin"],
  weight: ["400", "500", "600", "700", "900"],
  variable: "--font-poppins",
  display: 'swap'
});


MyApp.getInitialProps = async (ctx: NextPageContext) => {
  console.log("ctx", ctx.req);
  return {};
};


function MyApp({ Component, pageProps }: AppProps) {
  const font = "";
  const [theme, setTheme] = useState(null)
  const THEME_MODE = {
    "blue": "light",
    "dark": "dark",
    "light": "light",
    "research" : "dark",
    "purple": "dark",
    "grey": "dark",
  }

  useEffect(() => {
    // Function to load CSS dynamically
    const loadCss = (href: string) => {
      console.log("loading css")
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = href;
      document.head.appendChild(link);
      return () => {
        document.head.removeChild(link);
      };
    };
    console.log("theme", theme)
    return loadCss(`/themes/${theme}.css`);
  }, [theme])

  useEffect(() => {
    const listenStorageChange = () => {
      const localTheme = localStorage.getItem("theme")
      if (localTheme === null) {
        if (theme === null) {
          localStorage.setItem("theme", "dark")
          setTheme("dark")
        } else {
          localStorage.setItem("theme", theme)
        }
      } else {
        setTheme(localTheme)
      }
    };
    listenStorageChange();
    window.addEventListener("storage", listenStorageChange);
    return () => window.removeEventListener("storage", listenStorageChange);
  }, []);
  if (theme === null) {
    return
  }
  return (
    <><style
    jsx
    global
  >{`:root { --font-poppins: ${poppins.style.fontFamily};}}`}</style>
    <Theme appearance={THEME_MODE[theme]} grayColor="auto" accentColor={ "blue"}>
    <SuperTokensProvider>
      <CookiesProvider>
        <SWRConfig
          value={{
            fetcher: fetchJson,
          }}
        >
          <Head>
            <title>Tracking Tool</title>
            <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
          </Head>
          <main
            style={{background: "var(--color-background)"}}
            className={`${poppins.variable} content`}
          >
            <div>
              <Component {...pageProps} />
            </div>
            <div id="headlessui-portal-root">
              {/* Important that a div is inside the portal root */}
              <div />
            </div>
            <div id="radix-portal-root">
              {/* Important that a div is inside the portal root */}
              <div />
            </div>
          </main>
        </SWRConfig>
      </CookiesProvider>
    </SuperTokensProvider>
    </Theme>
    </>
  );
}

export default MyApp;
