"use client";
import ThirdPartyEmailPasswordReact from "supertokens-auth-react/recipe/thirdpartyemailpassword";
import SessionReact from "supertokens-auth-react/recipe/session";
import Router from "next/router";
import { authLogoUrl } from "./util";
import { useRouter } from "next/navigation";
import EmailPassword from 'supertokens-web-js/recipe/emailpassword'


const localhost = "https://wvemedia.localhost";


const routerInfo: { router?: ReturnType<typeof useRouter>; pathName?: string } =
  {};

export function setRouter(
  router: ReturnType<typeof useRouter>,
  pathName: string,
) {
  routerInfo.router = router;
  routerInfo.pathName = pathName;
}

function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split('&');
  for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      if (decodeURIComponent(pair[0]) == variable) {
          return decodeURIComponent(pair[1]);
      }
  }
}
export const frontendConfig = () => ({
  appInfo,
  recipeList: [
    SessionReact.init({
      tokenTransferMethod: "header",
    }),
    ThirdPartyEmailPasswordReact.init({
      useShadowDom: false,
      signInAndUpFeature: {
        providers: [],
      },
      style: `
                [data-supertokens~=container] {
                    --palette-background:0,0,0,0;
                    --palette-inputBackground: var(--color-main-secondary-split);
                    --palette-inputBorder: 41, 41, 41;
                    --palette-textTitle: var(--color-main-text-split);
                    --palette-textLabel: var(--color-main-text-split);
                    --palette-textPrimary: var(--color-main-text-split);
                    --palette-error: 173, 46, 46;
                    --palette-textInput: var(--color-main-text-split);
                    --palette-textLink: var(--color-main-text-split);
                    --palette-superTokensBrandingBackground: 0, 0, 0, 0;
                    --palette-superTokensBrandingText: 0, 0, 0, 0;
                    box-shadow: none !important;
                }
                #supertokens-root {
                    background-color: var(--color-main-background);
                }

div[data-supertokens="headerTitle"]::before {
  background-image: url(".${authLogoUrl(window.location.host)}");
content: ""; /* This is required for the pseudo-element to be generated */
  display: block; /* Adjust display as needed */
  width: auto; /* Desired width */
  height: 55px; /* Desired height */
  background-repeat: no-repeat;
  background-size: contain;

  background-position: center; 
  margin: auto; /* Adjust spacing */
    margin-bottom: 30px;
}

div[data-supertokens="headerSubtitle"] {
  display:none;
}

div {
  font-family: proxima-nova !important;
}

                [data-supertokens~=button] {
                    background-color: #333333;
                    border: 0px;
                    margin: 0 auto;
                }
                `
    }),
  ],
  windowHandler: (orig) => {
    return {
      ...orig,
      location: {
        ...orig.location,
        getPathName: () => routerInfo.pathName!,
        assign: (url) => {
          console.log("url", url)
          routerInfo.router!.push(url.toString())
        },
        setHref: (url) => {
          console.log("url2", url)
          routerInfo.router!.push(url.toString())},
      },
    };
  },
});

const env = process.env.NEXT_PUBLIC_ENV as any;
export const appInfo = {
  apiDomain: (env === "dev" || process.env.NODE_ENV === "development")
  ? "https://wvemedia.com"
  : typeof window !== "undefined"
    ? window.location.hostname
    : "https://wvemedia.com"
,
  websiteDomain:
    (env === "dev" || process.env.NODE_ENV === "development")
      ? "https://localhost:3000"
      : typeof window !== "undefined"
        ? `${window.location.hostname}`
        : "https://wvemedia.com",
  apiBasePath: "/backend/supertokens",
  // learn more about this on https://supertokens.com/docs/thirdpartyemailpassword/appinfo
  appName: "Tracking Tool",
};
