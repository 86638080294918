import { useEffect, useState } from "react";

export const formatNumber = (num: number, precision: number = 1) => {
  if (num === -1) return "";
  if (typeof num !== "number") return "";
  if (num >= 1e9) {
    return (num / 1e9).toFixed(precision) + "B";
  } else if (num >= 1e6) {
    return (num / 1e6).toFixed(precision) + "M";
  } else if (num >= 1e3) {
    return (num / 1e3).toFixed(precision) + "K";
  } else {
    return num.toString();
  }
};

export const aggregate = (posts: Post[] | undefined, key: string): number =>
  posts
    ?.map((item: any) => (item[key] ? item[key] : 0))
    .filter((n: number) => n !== -1)
    .reduce((a, b) => a + b, 0);

export const sortArrow = (dir: string) => (dir === "desc" ? "▼" : "▲");

export const shortenLink = (url: string) =>
  url.replace("https://www.", "").replace("https://", "");

export const linkType = (url: string) => {
  if (url.startsWith("#")) return "hashtag";
  if (url.startsWith("@")) return "username";
  if (/^https:\/\/(.+)\.tiktok.com\//.test(url)) return "tiktok";
  else if (url.startsWith("https://www.instagram.com")) return "instagram";
  else if (
    url.startsWith("https://twitter.com/") ||
    url.startsWith("https://x.com/")
  )
    return "twitter";
  else if (
    url.startsWith("https://youtube.com/shorts") ||
    url.startsWith("https://www.youtube.com/shorts")
  )
    return "youtube";
  else return null;
};

export const cleanLink = (url: string) =>
  url.replace(/\?igshid=.+/, "").replace(/\?si=.+/, "");

export const findPrice = (campaign: Campaign, post: Post) =>
  campaign?.prices.find(
    (p) => p.platform === post.type && p.username !== null && post.username !== null &&  p.username?.toLowerCase() == post.username?.toLowerCase(),
  );

export const logoUrl = (domain: string, invert?: boolean) => {
  if (domain === "wvemedia.com") {
    if (invert) {
      return "/newlogo.webp";

    } else {
      return "/newlogo-invert.webp";

    }
  }
  else if (domain === "nuwave.wvemedia.com") return "/nuwave.png";
  else if (domain === "tracking.nuwave.io") return "/nuwave.png";
  else if (domain === "www.researchwarrant.online")
    return "/researchwarrant.png";
  else if (domain.includes("localhost")) {
    if (invert) {
      return "/newlogo.webp";

    } else {
      return "/newlogo-invert.webp";

    }
  }
  else return `/${domain}`;
};

export const authLogoUrl = (domain: string) => {
  if (domain === "wvemedia.com") return "/newlogo-invert.webp";
  else if (domain === "nuwave.wvemedia.com") return "/nuwave.png";
  else if (domain === "tracking.nuwave.io") return "/nuwave.png";
  else if (domain === "www.researchwarrant.online")
    return "/researchwarrant-full.png";
    else if (domain.includes("localhost")) return "https://wvemedia.com/newlogo-invert.webp"
  else return `/${domain}`;
};

export const capitalize = (string: string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const usernameTemplate = (type: string) => {
  if (type == "instagram") return "https://instagram.com/";
  if (type == "twitter") return "https://twitter.com/";
  if (type == "tiktok") return "https://tiktok.com/@";
  if (type == "youtube") return "https://youtube.com/@";
};

export const useDebounce = (cb, delay) => {
  const [debounceValue, setDebounceValue] = useState(cb);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebounceValue(cb);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [cb, delay]);
  return debounceValue;
}

export const remainingBudget = (campaign: Campaign, platform: string) => {
  if (!campaign) return 0;
  const total = campaign.budgets
    .map((item) =>
      item.creatorPrice.platform === platform
        ? item.budget * item.creatorPrice.price
        : 0,
    )
    .reduce((a: any, b: any) => a + b, 0);
    let budget = 0
    if (campaign?.CampaignBudget?.length > 0) {
      campaign?.CampaignBudget?.forEach(b =>{
        if (b.platform === platform) {
          budget += b.budget
        }
        })
      return budget - total
    }
  return (
    (platform === "instagram"
      ? campaign.instagramBudget
      : campaign.tiktokBudget) - total
  );
};

export const getAbsDate = (date) => {
  // return absolute date without date offset
  return new Date( date.getTime() - date.getTimezoneOffset() * -60000 )
}